//

.customModal {
  --max-height: 95% !important;
  align-items: flex-end;
  --border-radius: 1.5625rem 1.5625rem 0 0;
  --ion-backdrop-color: #012544 !important;
}

.customModal2 {
  --min-height: 88% !important;
  align-items: flex-end;
  --border-radius: 1.5625rem 1.5625rem 0 0;
  --ion-backdrop-color: #012544 !important;

  &::part(content) {
    --border: 0.063rem solid #d4d4d4;
    border: 0.063rem solid #d4d4d4;
  }
}

.customModal4 {
  --max-height: 95% !important;
  align-items: flex-end;
  --border-radius: 1.5625rem 1.5625rem 0 0;
  --ion-backdrop-color: #012544 !important;

  &::part(content) {
    --border: 0.063rem solid #d4d4d4;
    border: 0.063rem solid #d4d4d4;
  }
}

.customModal-no-radius {
  --min-height: 88% !important;

  &::part(content) {
    --border: 0.063rem solid #d4d4d4;
    border: 0.063rem solid #d4d4d4;
  }
}

.customModal3 {
  --max-height: 45% !important;
  --border-radius: 1.5625rem 1.5625rem 0 0;
  align-items: flex-end;
  --ion-backdrop-color: #012544 !important;
  background: rgba(1, 37, 68, 0.45) !important;

  &::part(content) {
    --border: 0.063rem solid #d4d4d4;
    border: 0.063rem solid #d4d4d4;
  }
}

@for $i from 1 through 400 {
  .customModal-#{$i} {
    --max-height: calc(#{$i} * 1%) !important;
    --border-radius: 0.625rem 0.625rem 0 0;
    align-items: flex-end;
    // background: #858585bf;
    background: rgba(0, 0, 0, 0.4);

    &::part(content) {
      --border: 0.063rem solid #fff;
      border: 0.063rem solid #fff;
    }

    &.has-breakpoints,
    &.has-breakpoint {
      --border-radius: 1rem !important;
    }
  }

  .customModal-center-#{$i} {
    --max-height: calc(#{$i} * 1%) !important;
    --width: 19.375rem;
    --border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    align-items: center;
    // background: #858585bf;
    background: rgba(0, 0, 0, 0.4);

    &::part(content) {
      --border: 0.063rem solid #fff;
      border: 0.063rem solid #fff;
    }

    &.has-breakpoints,
    &.has-breakpoint {
      --border-radius: 0.5rem !important;
    }
  }

  .customModal-center-#{$i}-fixed {
    --height: calc(#{$i} * 0.063rem) !important;
    --width: 19.375rem;
    --border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    align-items: center;
    // background: #858585bf;
    background: rgba(0, 0, 0, 0.4);

    &::part(content) {
      --border: 0.063rem solid #fff;
      border: 0.063rem solid #fff;
    }

    &.has-breakpoints,
    &.has-breakpoint {
      --border-radius: 0.5rem !important;
    }
  }
}

.customModal-full {
  --min-height: 68% !important;
  // --border-radius: 0.625rem !important;
}

.customModal-web {
  --height: 100%;
  align-items: flex-start;
  justify-content: flex-end;
  --border-radius: 0 0 0 0;
  --width: 28.125rem;

  .ion-page {
    border-left: 0.125rem solid #607d8b;
  }
}

@media (min-width: 768px) {
  .customModal {
    --min-height: 88% !important;
    align-items: center;
    --border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem !important;
  }

  .customModal2 {
    --min-height: 88% !important;
    align-items: center;
    --border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem !important;
  }
}

@media (min-width: 992px) {
  .customModal {
    --min-height: 88% !important;
    align-items: center;
    --border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem !important;
  }

  .customModal2 {
    --min-height: 88% !important;
    align-items: center;
    --border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem !important;
  }
}

@media (min-width: 1024px) {
  .customModal {
    --min-height: 88% !important;
    align-items: center;
    --border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem !important;
  }

  .customModal2 {
    --min-height: 88% !important;
    align-items: center;
    --border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem !important;
  }
}

@media (min-width: 992px) {
  .customModal {
    --min-height: 88% !important;
    align-items: center;
    --border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem !important;
  }

  .customModal2 {
    --min-height: 88% !important;
    align-items: center;
    --border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem !important;
  }
}

@media (min-width: 1112px) {
  .customModal {
    --min-height: 88% !important;
    align-items: center;
    --border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem !important;
  }

  .customModal2 {
    --min-height: 88% !important;
    align-items: center;
    --border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem !important;
  }
}

@media (min-width: 1366px) {
  .customModal {
    --min-height: 88% !important;
    align-items: center;
    --border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem !important;
  }

  .customModal2 {
    --min-height: 88% !important;
    align-items: center;
    --border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem !important;
  }
}

// Popover
.account-switch-popover {
  --width: 16.375rem;

  &::part(content) {
    border-radius: 1.25rem;
  }

  &::part(arrow) {
    display: none;
  }

  .account-switch-popover__content {
    --padding-start: 0.813rem;
    --padding-end: 0.813rem;
    --padding-top: 1.25rem;
    --padding-bottom: 1.25rem;
    --background: #ffffff;
  }

  .account-switch-item {
    ion-avatar {
      width: 30px;
      height: 30px;
      flex-shrink: 0;
    }
  }

  .account-switch-item__title {
    font-size: 1rem;
    color: #666666;
  }
}
