.public-swiper {

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-container {
    overflow: visible !important;
  }

  span.swiper-pagination-bullet-active {
    height: 5px !important;
    width: 14px !important;
    background: #fff;
    border-radius: 5px;
    opacity: 1;
  }

  .swiper-pagination-bullet {
    height: 5px !important;
    width: 5px !important;
    background: #A6E6CF;
    opacity: 1;
  }

  .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 4px !important;
    border-radius: 10px;
  }

}

.registration-swiper {
  max-width: 26.25rem;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(140.02deg, rgba(255, 255, 255, 0.4) 13.5%, rgba(255, 255, 255, 0.1) 72.98%);
  border-width: 0.063rem;
  border-style: solid;
  border-image: linear-gradient(
    140.02deg,
    rgba(0, 0, 0, 0.1) 72.98%,
    rgba(0, 0, 0, 0.4) 13.5%
  );
  border-radius: 0.5rem;
  position: relative;
  ion-button.swiper-btn {
    position: absolute;
    bottom: 1.563rem;
    width: 2.125rem !important;
    height: 2.125rem !important;
    backdrop-filter: blur(2.5rem);
    z-index: 999;
    --background: #FFFFFF;
    --background-activated: #FFFFFF;
    --background-focused: #FFFFFF;
    --background-hover: #FFFFFF;
    --border-radius: 6.25rem;
    --box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;

    ion-icon {
      font-size: 0.875rem;
    }
  }

  ion-button.swiper-btn.left {
    left: 2.125rem;
  }

  ion-button.swiper-btn.right {
    right: 2.125rem;
  }

  .swiper-pagination {
    bottom: 2.375rem !important;

    .swiper-pagination-bullet {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background: #a6e6cf !important;
    }

    .swiper-pagination-bullet-active {
      width: 1rem;
      background: #ffffff !important;
      border-radius: 0.625rem !important;
    }
  }

  .registration-swiper-main-card {
    height: 15.563rem;
    margin: 0;
    box-shadow: none;
    background: transparent;

    .registration-swiper-card-content {
      color: #fff;
      height: 100%;
    }

    .registration-swiper-card-title {
      font-style: normal;
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 1.813rem;
      text-align: center;
    }

    .registration-swiper-card-desc {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.38rem;
      text-align: center;
    }
  }
}
