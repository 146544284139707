// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #039f68;
  --ion-color-primary-rgb: 3, 159, 104;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #038c5c;
  --ion-color-primary-tint: #1ca977;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #039f68;
  --ion-color-success-rgb: 3, 159, 104;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #038c5c;
  --ion-color-success-tint: #1ca977;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-pure-white: #ffffff;
  --ion-color-pure-black: #000000;
  --ion-color-stroke: #dadada;
  --ion-color-main-text: #121f3e;
  --ion-text-color-rgb: 83, 86, 97;
  --ion-color-main-black: #535661;
  --ion-color-content-bg: #F5F8FA;
  --ion-color-grey-light: #9a9c9e;

  --ion-color-blue-1: #f4f5f8;
  --ion-color-blue-2: #404d59;
}


ion-button {
  font-family: var(--ion-app-font-regular) !important;
  font-style: normal !important;
  font-size: 1rem;
  line-height: 1.625rem;
  text-align: center !important;
  --border-radius: 0.313rem;
  max-height: 2.813rem !important;
}

.flex {
  display: flex;
  width: 100%;
}
.flex-col {
  flex-direction: column;
}

::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;
}

* {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}



//

.link,
a,
.ion-activatable,
.company,
.clickable {
  cursor: pointer;
}


.explore-search-bar {
  padding-right: 0;
  padding-left: 0;
  color: var(--ion-kreadeet-color-29);

  .searchbar-input {
    padding-inline-start: 2.375rem !important;
  }

  .searchbar-search-icon {
    width: 0.875rem !important;
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }
}

ion-button {
  font-family: var(--ion-app-font-medium) !important;
  font-style: normal;
  font-weight: bold;
  font-size: 0.938rem !important;
  line-height: 1.5rem !important;
  letter-spacing: -0.01em;
  text-align: center;
  --border-radius: 0.188rem;
  height: 2.813rem !important;
  max-height: 3.75rem !important;

  &[size="small"] {
    font-weight: bold;
    font-size: 0.813rem !important;
    height: 2.188rem !important;
    --padding-start: 0.75rem;
    --padding-end: 0.75rem;
  }
}
