.custom-corporate-alert,
.corporate-alert {
  @media (max-width: 280px) {
    --min-width: 280px !important;
  }

  --min-width: 37.5rem !important;

  .alert-head {
    padding: 0;
  }

  .alert-title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #056643;
  }

  .alert-wrapper {
    padding: 0 90px 51px 90px;
    background: #ffffff;
  }

  .alert-message {
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
      display: flex;

      h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: #333333;
        margin: 0 !important;
      }

      h1.is-error {
        color: #cc2e2e;
      }
    }

    .alert-icon {
      display: flex;
    }

    .message {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: #00183399;
    }
  }

  .alert-message.sc-ion-alert-ios {
    max-height: 100%;
    padding-bottom: 0;
  }

  .alert-button {
    border: none !important;
    border-radius: 8px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
  }

  .corporate-alert-btn {
    color: #ffffff !important;
    height: 3.75rem;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 1rem !important;
    line-height: 1.188rem;
    text-align: center;

    &.success-btn {
      background-color: #056643 !important;
    }

    &.error-btn {
      background-color: #cc2e2e !important;
    }

    &.warning-btn {
      background-color: #e57a1a;
    }
  }
}
