.custom-loading {
  background-color: rgba(0, 0, 0, 0.75) !important;

  .loading-wrapper {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    padding: 12px;
    background-color: transparent !important;
    // var(--ion-color-chariot-text)
    backdrop-filter: none !important;

    .loading-content {
      min-width: 10px;
      width: 220px;

      #roller {
        top: 50%;
        left: 50%;
        width: 120px;
        height: 120px;
        -webkit-animation: zoom-in-and-out 2s linear infinite;
        -moz-animation: zoom-in-and-out 2s linear infinite;
        animation: zoom-in-and-out 2s linear infinite;
      }

      @-moz-keyframes zoom-in-and-out {
        0% {
          -moz-transform: scale(0.75);
        }

        25% {
          -moz-transform: scale(1.25);
        }

        50% {
          -moz-transform: scale(1.75);
        }

        75% {
          -moz-transform: scale(1.25);
        }

        100% {
          -moz-transform: scale(0.75);
        }
      }

      @-webkit-keyframes zoom-in-and-out {
        0% {
          -webkit-transform: scale(0.75);
        }

        25% {
          -webkit-transform: scale(1.25);
        }

        50% {
          -webkit-transform: scale(1.75);
        }

        75% {
          -webkit-transform: scale(1.25);
        }

        100% {
          -webkit-transform: scale(0.75);
        }
      }

      @keyframes zoom-in-and-out {
        0% {
          -webkit-transform: scale(0.75);
          transform: scale(0.75);
        }

        25% {
          -webkit-transform: scale(1.25);
          transform: scale(1.25);
        }

        50% {
          -webkit-transform: scale(1.75);
          transform: scale(1.75);
        }

        75% {
          -webkit-transform: scale(1.25);
          transform: scale(1.25);
        }

        100% {
          -webkit-transform: scale(0.75);
          transform: scale(0.75);
        }
      }
    }
  }
}
