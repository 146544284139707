ion-item {
  &.corporate-input-item {
    cursor: pointer;
    --padding-start: 0;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
    --background-hover: transparent;
    --background-activated: transparent;
    --background-focused: transparent;

    ion-label {
      font-weight: 400 !important;
      font-size: 1rem !important;
      line-height: 1.188rem !important;
      color: #5976a1 !important;
      margin-bottom: 0.313rem !important;
    }

    .input-icon {
      position: absolute;
      bottom: 0.813rem;
      left: 0.938rem;
      z-index: 999;
      font-size: 1rem;
    }

    .input-icon-left {
      position: absolute;
      bottom: 1.063rem;
      left: 0.938rem;
      z-index: 999;
      font-size: 1rem;
    }

    .input-icon-right {
      position: absolute;
      bottom: 1.063rem;
      right: 0.938rem;
      z-index: 999;
      font-size: 1rem;
    }

    .input-note-right {
      position: absolute;
      bottom: 0.5rem;
      right: 0.938rem;
      z-index: 999;
      font-size: 1rem;
    }

    .item-input {
      background: rgba(255, 255, 255, 0.5);
      border: 0.05rem solid #e8ebee;
      border-radius: 0.63rem;
      padding-left: 0.938rem !important;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      min-height: 3.125rem !important;
      color: #333333;
      --placeholder-color: #a5b0b7;
      &.has-icon-left {
        padding-left: 2.5rem !important;
      }

      &.has-icon-right {
        padding-right: 2.5rem !important;
      }

      &::part(icon) {
        background-image: url("../assets/icon/corporate/chevron-down.svg");
        width: 24px;
        height: 24px;
        opacity: 1;
        color: transparent;
      }

      &[readonly="true"] {
        background: #FAFFFD;
      }
    }

    &.item-has-value {
      &.ion-dirty.ion-invalid {
        .item-input {
          border: 0.063rem solid red;
        }
      }
    }

    &.item-has-value {
      .item-input {
        border: 0.063rem solid var(--ion-color-success);
      }
    }
  }
}

.searchbar-input.sc-ion-searchbar-ios {
  // font-size: 0.938rem !important;
  font-size: 0.875rem !important;
}

.searchbar-search-icon.sc-ion-searchbar-ios {
  left: 0.438rem;
  width: 1rem;
}
