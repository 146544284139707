.d-flex {
  display: flex;
}

.go-back {
  display: flex;
  cursor: pointer;

  .back-btn {
    display: flex;
    background: #056643;
    border-radius: 50%;
    width: 1.875rem;
    height: 1.875rem;
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: #303030;
  }
}

.card-footer-btn {
  flex: 1;
  height: 1.5rem !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.188rem;
  text-align: center;
  --padding-start: 0.313rem;
  --padding-end: 0.313rem;
  --padding-top: 0.313rem;
  --padding-bottom: 0.313rem;

  &.danger-btn {
    --color: #cc2e2e !important;
  }

  &.warning-btn {
    --color: #e57a1a;
  }

  &.info-btn {
    --color: #2d77f1 !important;
  }

  &.secondary-btn {
    --color: #ff3eb9;
  }
}

.corporate-card {
  background: rgba(255, 255, 255, 0.7);
  border: 0.063rem solid #ffffff;
  backdrop-filter: blur(0.25rem);
  border-radius: 1.25rem;

  .bg-white {
    background: #ffffff;
    border-color: #eeeeee;
  }
}

.corporate-card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.188rem;
  letter-spacing: 0.013rem;
  color: #515f68;
}

.overview-title {
  display: flex;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.188rem;
    letter-spacing: 0.013rem;
    color: #056643;
  }
}

.overview-card {
  display: flex;
  flex-direction: column;
  height: 7.188rem;
  background: #ffffff;
  border: 0.063rem solid #eeeeee;
  backdrop-filter: blur(0.25rem);
  border-radius: 0.625rem;

  .overview-card-title {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.188rem;
    letter-spacing: 0.013rem;
    color: #515f68;
    text-align: center;
  }

  .overview-card-val {
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.813rem;
    text-align: center;

    &.is-warning {
      color: #e57a1a;
    }

    &.is-danger {
      color: #cc2e2e;
    }

    &.is-primary {
      color: #056643;
    }
  }
}

ion-button.corporate-btn {
  --border-radius: 0.313rem;
  height: 3.375rem;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.188rem;
  text-align: center;

  &.small-border {
    --border-radius: 0.313rem;
  }

  &.primary-btn {
    --background: #056643 !important;
  }

  &.primary-btn.button-outline {
    --border-color: #056643;
    --background: transparent !important;
    --color: #056643;
  }

  &.primary-btn.button-disabled {
    --background: #e8f3f0 !important;
    opacity: 1;
  }

  &.danger-btn {
    --background: #cc2e2e !important;
  }

  &.danger-btn.button-outline {
    --border-color: #cc2e2e;
    --background: transparent !important;
    --color: #cc2e2e;
  }

  &.warning-btn {
    --background: #e57a1a;
  }

  &.secondary-btn {
    --background: #ff3eb9;
  }

  &.cancel-btn.button-outline {
    --border-color: #757a78;
    --color: #757a78;
  }
}

ion-button.modal-flat-btn {
  --background: transparent;
  --box-shadow: none;
  --border-radius: 0.5rem;
  --background-activated: #f1f1f1;
  --background-hover: #f1f1f1;
  --padding-start: 1rem;
  --padding-end: 1rem;
  --padding-top: 1rem;
  --padding-bottom: 1rem;
  --color: #056643;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.188rem;
  width: fit-content;
}

.corporate-content-bg {
  --background: #fafafa;
}

.corporate-metrics {
  padding: 0;
  ion-col {
    // --ion-grid-column-padding: 0.625rem;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  ion-col:first-child {
    padding-left: 0;
  }
  ion-col:last-child {
    padding-right: 0;
  }
}

.corporate-chart {
  height: 24.375rem;
  background: rgba(255, 255, 255, 0.7);
  border: 0.063rem solid #ffffff;
  backdrop-filter: blur(4px);
  border-radius: 1.25rem;
  .corporate-chart-title {
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.188rem;
    letter-spacing: 0.013rem;
    color: #515f68;
  }
  .corporate-chart-filter {
    display: flex;
    border-radius: 0.75rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .chart-wrapper {
    height: 20rem;
  }
}

.corporate-table-title {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.188rem;
  letter-spacing: 0.013rem;
  color: #333333;
}

span.see-all {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.063rem;
  letter-spacing: 0.013rem;
  color: #056643;
  cursor: pointer;
}

.table-filter {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.188rem;
  color: #666666;
}

.table-card {
  background: rgba(255, 255, 255, 0.7);
  border: 0.063rem solid #ffffff;
  border-radius: 1.25rem;
  overflow: hidden;
  position: relative;
  overflow-x: auto;
  .table-card-header {
    display: flex;
    .header-title {
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.188rem;
      letter-spacing: 0.013rem;
      color: #515f68;
    }
  }

  table {
    width: 100%;

    thead {
      tr {
        background: #fbfffd;
        border: 0.063rem solid #ffffff;
      }

      th {
        height: 4.313rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.188rem;
        letter-spacing: 0.013rem;
        color: #a5aeb5;
        padding-left: 0.938rem;
        padding-right: 0.938rem;
        text-align: left;
      }
    }

    td {
      height: 4.063rem;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.188rem;
      letter-spacing: -0.02em;
      color: #798892;
      padding-left: 0.938rem;
      padding-right: 0.938rem;

      .table-img {
        width: 2.185rem;
        height: 2.185rem;
        border-radius: 50%;
        background: rgba(5, 102, 67, 0.1);
        border: 1px solid #bedfd3;

        span {
          font-weight: 400;
          font-size: 1rem;
          line-height: 19px;
          letter-spacing: 0.2px;
          color: #464646;
        }

        ion-img {
          width: 100%;
          height: 100%;
          &::part(image) {
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }

      ion-checkbox {
        margin-inline: 0;
        --background: #fafafb;
        --border-color: #cfd4d9;
        --border-radius: 0.188rem;
        --border-width: 0.063rem;
        --size: 0.938rem;
      }

      .more-icon {
        font-size: 1.5rem;
        cursor: pointer;
      }
    }

    tbody {
      tr:nth-child(even) {
        background: rgba(244, 244, 244, 0.5);
        border: 0.063rem solid #f4f4f4;
      }
    }
  }
}

.whitespace-nowrap {
  white-space: nowrap;
}

// Modal
.modal-btn-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-corporate-modal {
  --background: transparent;
  --height: 90%;

  .corporate-modal-wrapper {
    position: absolute;
    height: auto;
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 20px;
    background: #ffffff;
    width: 100%;
  }
}

.action-confirmation-modal {
  --height: 25.688rem;
}

// Popover
.corporate-table-popover {
  --width: auto;
  --max-width: 200px;
  --background: #ffffff;
  --box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.04);
  border-radius: 10px;

  .popover-list-item {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.2px;
    color: #798892;
    cursor: pointer;
  }
}

.transaction-item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f3f5f7;

  .transaction-item-title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #5976a1;
    flex: 1;
  }

  .transaction-item-val {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    flex: 1;
  }
}

.empty-data {
  ion-img {
    width: 84px;
    height: 84px;
    margin: 0 auto 22px;
  }
  .empty-data__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #001928;
  }
  .empty-data__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #515f68;
  }
}

.corporate-funding-means {
  --width: 230px;
}
