.no-backdrop-shadow {
  &::part(backdrop) {
    background: transparent !important;
  }
}

.no-item-padding,
.ion-no-item-padding {
  --inner-padding-start: 0 !important;
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  --inner-padding-end: 0;
}

.ion-no-padding-left {
  --inner-padding-start: 0 !important;
  padding-left: 0;
}

.ion-no-padding-right {
  --inner-padding-end: 0 !important;
  padding-right: 0;
}

.ion-no-padding-top {
  --inner-padding-top: 0 !important;
  padding-top: 0;
}

.ion-no-padding-bottom {
  --inner-padding-bottom: 0 !important;
  padding-bottom: 0;
}

@for $i from 1 through 200 {
  .ion-padding-top-#{$i} {
    padding-top: calc(#{$i} * 0.063rem) !important;
  }

  .ion-padding-bottom-#{$i} {
    padding-bottom: calc(#{$i} * 0.063rem) !important;
  }

  .ion-padding-top-#{$i}-full {
    padding-top: calc(#{$i} * 0.063rem) !important;
    --padding-top: calc(#{$i} * 0.063rem) !important;
  }

  .ion-padding-bottom-#{$i}-full {
    padding-bottom: calc(#{$i} * 0.063rem) !important;
    --padding-bottom: calc(#{$i} * 0.063rem) !important;
  }

  .ion-padding-right-#{$i} {
    padding-right: calc(#{$i} * 0.063rem) !important;
    --padding-end: calc(#{$i} * 0.063rem) !important;
  }

  .ion-padding-left-#{$i} {
    padding-left: calc(#{$i} * 0.063rem) !important;
    --padding-start: calc(#{$i} * 0.063rem) !important;
  }

  .ion-padding-#{$i} {
    padding: calc(#{$i} * 0.063rem) !important;
  }

  .ion-padding-x-#{$i} {
    padding-right: calc(#{$i} * 0.063rem) !important;
    padding-left: calc(#{$i} * 0.063rem) !important;
  }

  .ion-padding-y-#{$i} {
    padding-top: calc(#{$i} * 0.063rem) !important;
    padding-bottom: calc(#{$i} * 0.063rem) !important;
  }

  .ion-padding-x-#{$i}-full {
    padding-right: calc(#{$i} * 0.063rem) !important;
    padding-left: calc(#{$i} * 0.063rem) !important;
    --padding-start: calc(#{$i} * 0.063rem) !important;
    --padding-end: calc(#{$i} * 0.063rem) !important;
  }

  .ion-padding-y-#{$i}-full {
    padding-top: calc(#{$i} * 0.063rem) !important;
    padding-bottom: calc(#{$i} * 0.063rem) !important;
    --padding-top: calc(#{$i} * 0.063rem) !important;
    --padding-bottom: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-top-#{$i} {
    margin-top: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-bottom-#{$i} {
    margin-bottom: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-top-#{$i}-full {
    margin-top: calc(#{$i} * 0.063rem) !important;
    --margin-top: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-bottom-#{$i}-full {
    margin-bottom: calc(#{$i} * 0.063rem) !important;
    --margin-bottom: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-right-#{$i} {
    margin-right: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-left-#{$i} {
    margin-left: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-#{$i} {
    margin: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-x-#{$i} {
    margin-right: calc(#{$i} * 0.063rem) !important;
    margin-left: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-y-#{$i} {
    margin-top: calc(#{$i} * 0.063rem) !important;
    margin-bottom: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-x-#{$i}-full {
    margin-right: calc(#{$i} * 0.063rem) !important;
    margin-left: calc(#{$i} * 0.063rem) !important;
    --margin-start: calc(#{$i} * 0.063rem) !important;
    --margin-end: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-y-#{$i}-full {
    margin-top: calc(#{$i} * 0.063rem) !important;
    margin-bottom: calc(#{$i} * 0.063rem) !important;
    --margin-top: calc(#{$i} * 0.063rem) !important;
    --margin-bottom: calc(#{$i} * 0.063rem) !important;
  }

  .height-#{$i} {
    height: calc(#{$i} * 1%) !important;
  }

  .width-#{$i} {
    width: calc(#{$i} * 1%) !important;
  }

  .height-#{$i}-rem {
    height: calc(#{$i} * 0.063rem) !important;
  }

  .width-#{$i}-rem {
    width: calc(#{$i} * 0.063rem) !important;
  }
}

.ion-padding-x {
  padding-right: calc(16 * 0.063rem) !important;
  padding-left: calc(16 * 0.063rem) !important;
  --padding-end: calc(16 * 0.063rem) !important;
  --padding-start: calc(16 * 0.063rem) !important;
}

.ion-padding-y {
  padding-top: calc(16 * 0.063rem) !important;
  padding-bottom: calc(16 * 0.063rem) !important;
}

.ion-margin-x {
  margin-right: calc(16 * 0.063rem) !important;
  margin-left: calc(16 * 0.063rem) !important;
}

.ion-margin-y {
  margin-top: calc(16 * 0.063rem) !important;
  margin-bottom: calc(16 * 0.063rem) !important;
}

@for $i from 1 through 200 {
  .ion-padding-top-#{$i} {
    padding-top: calc(#{$i} * 0.063rem) !important;
  }

  .ion-padding-bottom-#{$i} {
    padding-bottom: calc(#{$i} * 0.063rem) !important;
  }

  .ion-padding-top-#{$i}-full {
    padding-top: calc(#{$i} * 0.063rem) !important;
    --padding-top: calc(#{$i} * 0.063rem) !important;
  }

  .ion-padding-bottom-#{$i}-full {
    padding-bottom: calc(#{$i} * 0.063rem) !important;
    --padding-bottom: calc(#{$i} * 0.063rem) !important;
  }

  .ion-padding-right-#{$i} {
    padding-right: calc(#{$i} * 0.063rem) !important;
    --padding-end: calc(#{$i} * 0.063rem) !important;
  }

  .ion-padding-left-#{$i} {
    padding-left: calc(#{$i} * 0.063rem) !important;
    --padding-start: calc(#{$i} * 0.063rem) !important;
  }

  .ion-padding-#{$i} {
    padding: calc(#{$i} * 0.063rem) !important;
  }

  .ion-padding-x-#{$i} {
    padding-right: calc(#{$i} * 0.063rem) !important;
    padding-left: calc(#{$i} * 0.063rem) !important;
  }

  .ion-padding-y-#{$i} {
    padding-top: calc(#{$i} * 0.063rem) !important;
    padding-bottom: calc(#{$i} * 0.063rem) !important;
  }

  .ion-padding-#{$i}-full {
    padding-right: calc(#{$i} * 0.063rem) !important;
    padding-left: calc(#{$i} * 0.063rem) !important;
    --padding-start: calc(#{$i} * 0.063rem) !important;
    --padding-end: calc(#{$i} * 0.063rem) !important;
    padding-top: calc(#{$i} * 0.063rem) !important;
    padding-bottom: calc(#{$i} * 0.063rem) !important;
    --padding-top: calc(#{$i} * 0.063rem) !important;
    --padding-bottom: calc(#{$i} * 0.063rem) !important;
  }

  .ion-padding-x-#{$i}-full {
    padding-right: calc(#{$i} * 0.063rem) !important;
    padding-left: calc(#{$i} * 0.063rem) !important;
    --padding-start: calc(#{$i} * 0.063rem) !important;
    --padding-end: calc(#{$i} * 0.063rem) !important;
  }

  .ion-padding-y-#{$i}-full {
    padding-top: calc(#{$i} * 0.063rem) !important;
    padding-bottom: calc(#{$i} * 0.063rem) !important;
    --padding-top: calc(#{$i} * 0.063rem) !important;
    --padding-bottom: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-top-#{$i} {
    margin-top: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-bottom-#{$i} {
    margin-bottom: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-top-#{$i}-full {
    margin-top: calc(#{$i} * 0.063rem) !important;
    --margin-top: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-bottom-#{$i}-full {
    margin-bottom: calc(#{$i} * 0.063rem) !important;
    --margin-bottom: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-right-#{$i} {
    margin-right: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-left-#{$i} {
    margin-left: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-#{$i} {
    margin: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-x-#{$i} {
    margin-right: calc(#{$i} * 0.063rem) !important;
    margin-left: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-y-#{$i} {
    margin-top: calc(#{$i} * 0.063rem) !important;
    margin-bottom: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-x-#{$i}-full {
    margin-right: calc(#{$i} * 0.063rem) !important;
    margin-left: calc(#{$i} * 0.063rem) !important;
    --margin-start: calc(#{$i} * 0.063rem) !important;
    --margin-end: calc(#{$i} * 0.063rem) !important;
  }

  .ion-margin-y-#{$i}-full {
    margin-top: calc(#{$i} * 0.063rem) !important;
    margin-bottom: calc(#{$i} * 0.063rem) !important;
    --margin-top: calc(#{$i} * 0.063rem) !important;
    --margin-bottom: calc(#{$i} * 0.063rem) !important;
  }

  .min-height-#{$i} {
    min-height: calc(#{$i} * 1%) !important;
  }

  .max-height-#{$i} {
    max-height: calc(#{$i} * 1%) !important;
  }

  .min-width-#{$i} {
    min-width: calc(#{$i} * 1%) !important;
  }

  .max-width-#{$i} {
    max-width: calc(#{$i} * 1%) !important;
  }

  .min-height-#{$i}-px {
    min-height: calc(#{$i} * 1px) !important;
  }

  .max-height-#{$i}-px {
    max-height: calc(#{$i} * 1px) !important;
  }

  .min-width-#{$i}-px {
    min-width: calc(#{$i} * 1px) !important;
  }

  .max-width-#{$i}-px {
    max-width: calc(#{$i} * 1px) !important;
  }

  .height-#{$i} {
    height: calc(#{$i} * 1%) !important;
  }

  .width-#{$i} {
    width: calc(#{$i} * 1%) !important;
  }

  .height-#{$i}-px {
    height: calc(#{$i} * 1px) !important;
  }

  .width-#{$i}-px {
    width: calc(#{$i} * 1px) !important;
  }

  .height-#{$i}-rem {
    height: calc(#{$i} * 1rem) !important;
  }

  .width-#{$i}-rem {
    width: calc(#{$i} * 1rem) !important;
  }
}

.ion-margin-auto {
  margin: auto;
}

a,
.clickable {
  cursor: pointer;
}
