ion-item {
  &.input-item {
    cursor: pointer;
    --padding-start: 0;
    --inner-padding-start: 0;
    --inner-padding-end: 0;

    ion-label {
      font-style: normal !important;
      font-weight: bold !important;
      font-size: 0.875rem !important;
      line-height: 1.188rem !important;
      letter-spacing: 0.02rem !important;
      color: #535661 !important;
      margin-bottom: 0.75rem !important;
      margin-top: 0 !important;
    }

    .input-icon {
      position: absolute;
      bottom: 0.938rem;
      left: 0.938rem;
      z-index: 999;
      font-size: 1rem;
    }

    .input-icon-left {
      position: absolute;
      bottom: 0.938rem;
      left: 0.938rem;
      z-index: 999;
      font-size: 1rem;
    }

    .input-icon-right {
      position: absolute;
      bottom: 0.938rem;
      right: 0.938rem;
      z-index: 999;
      font-size: 1rem;
    }

    .input-note-right {
      position: absolute;
      bottom: 5px;
      right: 0.938rem;
      z-index: 999;
      font-size: 1rem;
      margin-right: 0;
    }

    .item-input {
      background: var(--ion-color-pure-white);
      border: 0.063rem solid #d9dde2;
      border-radius: 0.313rem;
      padding-left: 0.938rem !important;

      font-style: normal;
      font-weight: bold;
      font-size: 0.75rem;
      line-height: 1rem; //   letter-spacing: -0.02em;
      color: #282c2e;
      min-height: 2.813rem !important;

      &.has-icon-left {
        padding-left: 2.5rem !important;
      }

      &.has-icon-right {
        padding-right: 2.5rem !important;
      }
    }

    &.item-has-value {
      &.ion-dirty.ion-invalid {
        .item-input {
          border: 0.063rem solid red;
        }
      }
    }

    &.item-has-value {
      .item-input {
        border: 0.063rem solid var(--ion-color-success);
      }
    }
  }
}

.searchbar-input.sc-ion-searchbar-ios {
  // font-size: 0.938rem !important;
  font-size: 0.875rem !important;
}

.searchbar-search-icon.sc-ion-searchbar-ios {
  left: 0.438rem;
  width: 1rem;
}
