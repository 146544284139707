@font-face {
  font-family: "AvenirNext-Bold";
  src: url("../assets/font/AvenirNext/AvenirNext-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
  letter-spacing: 0.02em !important;
  // font-feature-settings: 'salt' on;
}

@font-face {
  font-family: "AvenirNext-SemiBold";
  src: url("../assets/font/AvenirNext/AvenirNext-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
  letter-spacing: 0.02em !important;
  // font-feature-settings: 'salt' on;
}

@font-face {
  font-family: "AvenirNext-Medium";
  src: url("../assets/font/AvenirNext/AvenirNext-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
  letter-spacing: 0.02em !important;
  // font-feature-settings: 'salt' on;
}

@font-face {
  font-family: "AvenirNext-Regular";
  src: url("../assets/font/AvenirNext/AvenirNext-Regular.ttf") format("truetype");
  font-weight: normal;
  // font-weight: Bold;
  font-style: normal;
  text-rendering: optimizeLegibility;
  letter-spacing: 0.02em !important;
  // font-feature-settings: 'salt' on;
}

@font-face {
  font-family: "AvenirNext-UltraLight";
  src: url("../assets/font/AvenirNext/AvenirNext-UltraLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
  letter-spacing: 0.02em !important;
  // font-feature-settings: 'salt' on;
}

:root {
  -webkit-font-smoothing: antialiased;
  --ion-font-family: "AvenirNext-Regular", "AvenirNext-Medium",
    "AvenirNext-Bold", "AvenirNext-SemiBold", "AvenirNext-UltraLight";
  --ion-default-font: "AvenirNext-Regular", "AvenirNext-Medium",
    "AvenirNext-Bold", "AvenirNext-SemiBold", "AvenirNext-UltraLight";
  font-family: var(--ion-font-family) !important;
  line-height: 1.3;
  letter-spacing: 0.02em !important;
  // font-feature-settings: 'salt' on;

  --ion-app-font-bold: "AvenirNext-Bold";
  --ion-app-font-medium: "AvenirNext-Medium";
  --ion-app-font-regular: "AvenirNext-Regular";
  --ion-app-font-semi-bold: "AvenirNext-SemiBold";
  --ion-app-font-ultra-light: "AvenirNext-UltraLight";
}
