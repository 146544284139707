ion-header,
ion-toolbar,
ion-footer {
  --background: var(--ion-color-pure-white);
  background: var(--ion-color-pure-white);
}

ion-toolbar {
  --background: var(--ion-color-white) !important;
  background: var(--ion-color-white) !important;
  --border-color: var(--ion-color-white) !important;
  --min-height: 3.75rem;

  &.ion-no-border {
    --border-color: transparent !important;
  }
}

// .md,
// .ios {
//   ion-toolbar {
//     // margin-top: 0.125rem !important;
//     margin-top: 1rem !important;
//   }
// }

.top-header {
  ion-toolbar {
    --border-color: var(--ion-color-white) !important;
    min-height: 4.063rem !important;
    --min-height: 4.063rem !important;

    display: flex;

    &.ion-no-border {
      --border-color: transparent !important;
    }

    .greeting {
      font-family: var(--ion-app-font-medium);
      font-style: normal;
      font-weight: Bold;
      font-size: 1.125rem;
      line-height: 1.375rem;
      color: var(--ion-color-main-black);

      span {
        font-weight: 300;
        font-family: var(--ion-app-font-regular);
      }
    }
  }
}

.slide_style_3 {

  .swiper-container-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 0 !important;
  }
}

.ion-page {
  background: var(--ion-color-white) !important;
}

.item-interactive-disabled:not(.item-multiple-inputs) {
  ion-label {
    opacity: 1;
  }
}

.action-sheet-title {
  font-weight: bold;
}

.action-sheet-button {
  // color: var(--ion-color-main-black) !important;
  font-weight: bold;
  font-size: 1rem !important;
}

.action-sheet-selected {
  color: var(--button-color-selected) !important;
}

.cookies {
  --max-width: 50rem !important;
  --border-radius: 0.313rem;
  --border-color: var(--ion-color-stroke);
  --border-style: solid !important;
  --border-width: 0.063rem;
  --background: var(--ion-color-white) !important;
  --box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.04);

  .toast-button-group {
    .toast-button {
      font-size: 0.813rem !important;
      line-height: 0.938rem !important;
    }

    .secondary {
      --button-color: var(--ion-color-textfield-stroke);
      background-color: var(--ion-color-textfield-stroke);
      color: var(--ion-color-black);
    }

    .primary {
      --button-color: var(--ion-color-success);
    }
  }

  &::part(message) {
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--ion-color-body);
  }

  &::part(button) {
    font-size: 0.813rem;
    line-height: 0.938rem;

    &:nth-child(0) {
      background-color: var(--ion-color-textfield-stroke);
      color: var(--ion-color-black);
    }

    &.primary {
      background-color: var(--ion-color-success);
    }
  }
}

.skeleton-white {
  --background-rgb: 255, 255, 555;
  --border-radius: 0.313rem;
}

// cookies::

.picker-item {
  cursor: pointer;
}

.custom-date-picker {
  ::part(buttons) {
    font-family: var(--ion-app-font-regular);
    font-style: normal;
    font-size: 0.813rem;
    color: var(--ion-color-body);
  }

  ::part(time-label) {
    font-family: var(--ion-app-font-regular);
    font-style: normal;
    font-size: 0.813rem;
    color: var(--ion-color-body);
  }

  ::part(title) {
    font-family: var(--ion-app-font-regular);
    font-style: normal;
    font-size: 0.813rem;
    color: var(--ion-color-body);
  }

  .calendar-month-grid {
    padding: 1.188rem !important;
  }

  .calendar-day {
    font-family: var(--ion-app-font-regular);
    font-style: normal;
    font-size: 1rem;
    color: var(--ion-color-body);
  }
}

button {
  font-size: 0.813rem !important;
  color: var(--ion-color-body);
}

ion-breadcrumb {
  cursor: pointer;
  font-family: var(--ion-app-font-regular);
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  // line-height: 1rem
  letter-spacing: -0.313rem;
  color: var(--ion-color-black);

  &.breadcrumb-active {
    color: var(--ion-color-success);
  }
}

.light-button {
  --border-color: #dadada !important;
}

.emoji-button {
  font-size: 1.5rem !important;
}

emoji-picker {
  transform: translate(1.5rem, -0.375rem) !important;
}

a,
.button {
  cursor: pointer;
}



// @media screen and (min-width: 1428px) {
// @media screen and (min-width: 1279px) {
//   ion-app {
//     width: 96%;
//     left: 2% !important;
//     top: 0.938rem !important;
//     background: var(--ion-color-pure-white);
//     box-shadow: 0 0 0.5rem 0 hsl(0deg 0% 59% / 20%);
//   }

//   body {
//     background: linear-gradient(#0b3224 16%, #f4f6f5 0) !important;
//   }
// }

// @media screen and (min-width: 1428px) {
//   ion-app {
//     width: 88%;
//     left: 6% !important;
//     top: 0.938rem !important;
//     background: var(--ion-color-pure-white);
//     box-shadow: 0 0 0.5rem 0 hsl(0deg 0% 59% / 20%);
//   }

//   body {
//     background: linear-gradient(#0b3224 16%, #f4f6f5 0) !important;
//   }
// }

::-webkit-scrollbar {
  display: none !important;
}

* {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
