.custom-alert,
.my-alert {
  @media (max-width: 280px) {
    --min-width: 280px !important;
  }

  --min-width: 345px !important;

  .alert-button-inner {
    justify-content: center !important;
  }

  .alert-head {
    padding-top: 16px;
  }

  .alert-title {
    font-family: var(--ion-app-font-medium);
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #212b36;
    margin-bottom: 0;
    margin-top: 0;
    padding-right: 8px;
    padding-left: 8px;
  }

  .alert-wrapper {
    padding: 2px 0 32px 0;
  }

  .alert-message {
    padding-top: 14px;
    padding-bottom: 24px;
    padding-right: 24px;
    padding-left: 24px;

    img {
      width: 40px !important;
    }

    .header {
      font-family: var(--ion-app-font-medium);
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.333333px;
      color: var(--ion-color-base, #212b36);
      // margin-top: 0 !important;
    }

    .message {
      font-family: var(--ion-app-font-regular);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #606972;
      margin: auto;

      b {
        color: #212b36;
        font-family: var(--ion-app-font-medium);
      }

      h1 {
        font-family: var(--ion-app-font-medium);
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
      }

      .token {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
        color: #2D3338;
        margin: 8px;
      }
    }
  }

  .alert-button-group {
    padding: 0 10px;
  }

  .only {
    max-width: 100px;
    margin: auto !important;
  }

  .alert-button {
    border: none !important;
    border-radius: 8px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
    min-width: 47%;
    margin-right: 1.5%;
    margin-left: 1.5%;

    .alert-button-inner {
      font-size: 14px;
    }
  }

  .secondary {
    font-family: var(--ion-app-font-medium);
    background-color: var(--ion-color-secondary) !important;
    color: var(--ion-color-secondary-contrast,
        var(--ion-color-pure-white)) !important;

    &.ion-activated {
      background-color: var(--ion-color-secondary-shade) !important;
    }
  }

  .medium {
    font-family: var(--ion-app-font-medium);
    background-color: var(--ion-color-medium) !important;
    color: var(--ion-color-medium-contrast,
        var(--ion-color-pure-white)) !important;

    &.ion-activated {
      background-color: var(--ion-color-medium-shade) !important;
    }
  }

  .primary {
    font-family: var(--ion-app-font-medium);
    background-color: var(--ion-color-primary) !important;
    color: var(--ion-color-primary-contrast,
        var(--ion-color-pure-white)) !important;

    &.ion-activated {
      background-color: var(--ion-color-primary-shade) !important;
    }
  }

  .danger {
    font-family: var(--ion-app-font-medium);
    background-color: var(--ion-color-danger) !important;
    color: var(--ion-color-danger-contrast,
        var(--ion-color-pure-white)) !important;

    &.ion-activated {
      background-color: var(--ion-color-danger-shade) !important;
    }
  }

  .success {
    font-family: var(--ion-app-font-medium);
    background-color: var(--ion-color-success) !important;
    color: var(--ion-color-success-contrast,
        var(--ion-color-pure-white)) !important;

    &.ion-activated {
      background-color: var(--ion-color-success-shade) !important;
    }
  }

  .alert-button-inner {
    justify-content: center !important;
  }

  @media (min-width: 420px) {
    .alert-button-group {
      width: 70%;
      margin-left: auto;
    }

    .alert-button {

      &.only {
        margin-right: 24px !important;
      }
    }

    .alert-title,
    .alert-message,
    .message,
    .alert-head,
    .alert-title {
      // text-align: inherit;
    }
  }
}
